<template>
  <div>
    <!-- MENÜ -->
    <!-- HAMBURGER MENÜ v2.0 -->
    <div
      style="background-color: #fcfcfc; padding-top: 5px"
      class="sticky-hamburger-content"
    >
      <div
        class="h-[90px] md:h-[138px] w-full bg-white main relative z-50 shadow-md transform duration-500"
        v-if="isXs"
      >
        <div class="w-[min(100%,1300px)] mx-auto h-full flex relative">
          <div class="block lg:hidden my-auto ml-auto px-3 pb-3 pt-1" v-show="isXs">
            <input type="checkbox" id="checkbox" v-model="menuOpen" />
            <label for="checkbox" class="toggle">
              <div class="bars" id="bar1"></div>
              <div class="bars" id="bar2"></div>
              <div class="bars" id="bar3"></div>
            </label>
            <div
              class="absolute left-0 top-full w-full text-gray-600 text-lg menu-content bg-white leading-10"
              id="mobile-menu"
              :class="{ 'menu-open': menuOpen }"
            >
              <ul class="w-[90%] mx-auto font-bold py-10">
                <li class="">
                  <a href="#welcome" class="custom-btn" @click="closeMenu">Főoldal</a>
                </li>
                <li class="">
                  <a href="#hazirend" class="custom-btn" @click="closeMenu">Házirend</a>
                </li>
                <li class="">
                  <a href="#kezelesek" class="custom-btn" @click="closeMenu">Kezelések</a>
                </li>
                <li class="">
                  <a href="#arlista" class="custom-btn" @click="closeMenu">Árlista</a>
                </li>
                <li class="">
                  <a href="#elerhetosegek" class="custom-btn" @click="closeMenu"
                    >Elérhetőségek</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / HAMBURGER MENÜ v2.0 -->
    <!-- DESKTOP MENÜ -->
    <div class="sticky-menu-content" v-if="!isXs">
      <v-row>
        <v-col class="d-flex justify-space-around px-auto pt-3 mt-3 text-center">
          <v-sheet>
            <a href="#welcome" class="custom-btn">Főoldal</a>
            <a href="#hazirend" class="custom-btn">Házirend</a>
            <a href="#kezelesek" class="custom-btn">Kezelések</a>
            <a href="#arlista" class="custom-btn">Árlista</a>
            <a href="#elerhetosegek" class="custom-btn">Elérhetőségek</a>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <!-- / DESKTOP MENÜ -->
    <!-- / MENÜ -->
  </div>
</template>

<script>
export default {
  name: "AppHeader",

  data: () => ({
    menuOpen: false,
    menuClick: false,
  }),
  computed: {
    // Ellenőrizzük, hogy az aktuális ablakméret Xs
    isXs() {
      const { xs, sm, md, lg, xl } = this.$vuetify.breakpoint;
      return !(lg || xl || md || sm); // Csak akkor igaz, ha xs, de nem nagyobb
    },
  },
  methods: {
    closeMenu() {
      this.menuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  z-index: 99;
  padding-top: 15px;
  margin-top: 15px;
}
#checkbox {
  display: none;
}
.toggle {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition-duration: 0.5s;
}
.bars {
  width: 80%;
  height: 4px;
  background-color: rgb(73, 80, 87);
  border-radius: 3px;
  transition-duration: 0.5s;
  position: relative;
}
#checkbox:checked ~ .toggle #bar1 {
  transform: rotate(45deg) translate(5px, 5px);
  transform-origin: center;
  transition-duration: 0.5s;
}
#checkbox:checked ~ .toggle #bar3 {
  transform: rotate(-45deg) translate(5px, -5px);
  transform-origin: center;
  transition-duration: 0.5s;
}
#checkbox:checked ~ .toggle #bar2 {
  opacity: 0;
  height: 0;
  transform: scale(0);
  transition-duration: 0.5s;
}
.menu-content {
  opacity: 0;
  display: none;
  transform: translateY(-10%);
  transition: transform 0.6s ease, opacity 0.5s ease;
  z-index: 0;
}
.menu-content.menu-open {
  transform: translateY(0);
  opacity: 1;
  display: block;
  z-index: 199;
}
#checkbox:checked ~ .menu-content {
  transform: translateY(0);
  opacity: 1;
  display: block;
  z-index: 99;
}
ul {
  list-style-type: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}
.sticky-menu-content {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-top: 0;
  background-color: #fcfcfc;
  padding-bottom: 0;
}
.sticky-hamburger-content {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-top: 5px;
  background-color: #fcfcfc;
  padding-bottom: 0;
}
.custom-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #fcfcfcfc;
  color: #b5a2a5;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  font-family: "Roboto, Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  font-size: 0.9rem;
  // font-weight: lighter;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.custom-btn:hover {
  background-color: #fcfcfcfc;
  font-weight: bold;
  // color: #6b5344;
  color: #8b6c57;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.custom-btn:active {
  background-color: #fcfcfcfc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.header {
  background-color: #fff;
  position: relative;
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: left;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
  .header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
</style>
