<template>
  <div>
    <AppHeader />
    <Welcome id="welcome" />
    <Hazirend id="hazirend" />
    <Kezelesek id="kezelesek" />
    <Arlista id="arlista" />
    <Elerhetosegek id="elerhetosegek" />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import Welcome from "@/components/Welcome.vue";
import Hazirend from "@/components/Hazirend.vue";
import Kezelesek from "@/components/Kezelesek.vue";
import Arlista from "@/components/Arlista.vue";
import Elerhetosegek from "@/components/Elerhetosegek.vue";

export default {
  name: "Layout",
  components: {
    AppHeader,
    Welcome,
    Hazirend,
    Kezelesek,
    Arlista,
    Elerhetosegek,
  },

// A lap újratöltésénél az aktuális route-ra ugrik
  mounted() {
    const target = document.getElementById(this.$route.path.slice(1));
    target.scrollIntoView({ block: "start", behavior: "smooth" });
    console.log(target);
  },
};
</script>
