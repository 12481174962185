<template>
  <div id="welcome">
    <v-row class="d-flex justify-center text-center">
      <v-col cols="12" class="hover1" ref="top">
        <figure>
          <img :src="require('@/assets/logo.png')" contain height="250" />
        </figure>
      </v-col>
      <v-col>
        <h3 class="font-weight-bold text-uppercase">
          Ragyogj Te is a Shining Body Alakformáló Stúdió segítségével!
        </h3>
        <div class="d-flex justify-center">
          <v-sheet max-width="800" class="black--text text-center mt-2" color="white">
            <p class="text-caption font-weight-regular">
              Maximális szakmai tapasztalat, professzionális esztétikai kezelések és
              gépek. Ingyenes állapotfelmérés és bőrdiagnosztika. Itt minden problémádra
              megtalálod a megoldást!<br /><br />
              A fő szempontom mindig is az volt, hogy egy kellemes, barátságos hangulatot
              teremtsek vendégeimnek, hogy szeressék a közös munkát, és élvezzék az itt
              töltött időt. <br />Mert mindenki megérdemli, hogy
              <span class="font-weight-bold text-uppercase">Ragyogjon!</span>
            </p>
          </v-sheet>
        </div>
        <!-- KÉPEK -->
        <v-sheet
          class="mx-auto align-self-center"
          min-height="500"
          max-height="600"
          max-width="400"
          width="350"
        >
          <v-carousel cycle interval="3500" hide-delimiters :show-arrows="false">
            <v-carousel-item src="@/assets/szalon/2.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/3.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/4.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/5.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/6.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/7.jpeg" cover></v-carousel-item>
            <v-carousel-item src="@/assets/szalon/8.jpeg" cover></v-carousel-item>
          </v-carousel>
        </v-sheet>
        <!-- KÉPEK -->
        <br />
        <v-sheet
          max-width="25%"
          class="mx-auto text-caption black--text text-center"
          color="white"
        >
          <v-divider class="black"></v-divider>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.hover1 figure {
  position: relative;
  overflow: hidden;
}
.hover1 figure::before {
  /* Logo fény */
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover1 figure::before {
  -webkit-animation: shine 5s;
  animation: shine 5s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
div {
  position: relative;
  overflow: hidden;
}
</style>
